export default {
    DataTable: () => import("./DataTable.vue"),
    ActionButton: () => import("./ActionButton"),
    Select: () => import("./Select"),
    AggregateFunction: () => import("./AggregateFunction"),
    AuthorizedSignature: () => import("./AuthorizedSignature"),
    AutoIncrement: () => import("./AutoIncrement"),
    CheckBoxGroup: () => import("./CheckBoxGroup"),
    Checkbox: () => import("./Checkbox"),
    Currency: () => import("./Currency"),
    DIV: () => import("./DIV"),
    DateTimeRange: () => import("./DateTimeRange"),
    DateTime: () => import("./DateTime"),
    DateRange: () => import("./DateRange"),
    Date: () => import("./Date"),
    ESignature: () => import("./ESignature"),
    EntityVariable: () => import("./EntityVariable"),
    Entity: () => import("./Entity"),
    File: () => import("./File"),
    Formula: () => import("./Formula"),
    GlobalVariable: () => import("./GlobalVariable"),
    HorizontalLine: () => import("./HorizontalLine"),
    Html: () => import("./Html"),
    Image: () => import("./Image"),
    List: () => import("./List"),
    MultiLineText: () => import("./MultiLineText"),
    MultiSelect: () => import("./MultiSelect"),
    Number: () => import("./Number"),
    Paragraph: () => import("./Paragraph"),
    PhoneCountryCode: () => import("./PhoneCountryCode"),
    Radio: () => import("./Radio"),
    RadioButtonGroup: () => import("./RadioButtonGroup"),
    SingleLineContent: () => import("./SingleLineContent"),
    SingleLineText: () => import("./SingleLineText"),

    Time: () => import("./Time"),
    Video: () => import("./Video"),
    YesOrNo: () => import("./YesOrNo"),
    WeekDays: () => import("./WeekDays"),
    TimeRange: () => import("./TimeRange"),
    StarRating: () => import("./StarRating"),
    Concatenate: () => import("./Concatenate"),
    EntityTable: () => import("./EntityTable"),
    PayButtonView: () => import("./Payment")
}
