import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

import {
  paginationHelper,
  getPaginationResponse,
} from "../utils/paginationHelper";

import store from "@/store";

//delete entity by id
export const deleteGroup = async (groupId) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  const [selectedGroup] = await db.entity_groups
    .where("_id")
    .equals(groupId)
    .primaryKeys();
  if (selectedGroup) {
    await db.entity_groups.delete(selectedGroup);
  }
};

//update single entity
export const updateGroupData = async (groupId, data) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  if(data?.id){
    delete data.id;
  }
  const [selectedGroup] = await db.entity_groups
    .where("_id")
    .equals(groupId)
    .primaryKeys();
  if (selectedGroup) {
    await db.entity_groups.delete(selectedGroup);
    await addSingleGroupToLocal(data);
  } else {
    await addSingleGroupToLocal(data);
  }
};

//fetch groups groups
export const addSingleGroupToLocal = async (groupData) => {
  let appUsingMode = store.getters["auth/getAppMode"];
  if(!appUsingMode){
    return
  }
  return db.entity_groups.add({
    ...groupData,
    ...{ createdAt: new Date().toISOString() },
  });
};


//fetch groups groups
export const fetchGroups = async (params) => {
  let entityViews;
  entityViews = await fetchGroupsFromLocal(params);
  if (!entityViews) {
    entityViews = await fetchGroupsFromDatabase(params);
  }
  return entityViews;
};

export const fetchGroupsFromLocal = async (params) => {
  let collection = db.entity_groups;
  if (params.order_by) {
    collection = collection.orderBy(params.order_by);
    if (params.order_type === "desc") {
      collection = collection.reverse();
    }
  } else {
    collection = collection.orderBy("updated_at");
    collection = collection.reverse();
  }
  const regex = new RegExp(params.search_string || "", "i");
  if (params?.search_string) {
    collection = collection.filter((view) => {
      return regex.test(view.name);
    });
  }

  const count = await collection.count();
  if (params.limit) {
    params.skip = (params.page - 1) * params.limit;
  }
  collection = paginationHelper(collection, params);
  const results = await collection.toArray();
  if (!results || !results.length) {
    return null;
  }
  return getPaginationResponse({
    page: params.page || 1,
    count,
    limit: params.limit || 10,
    skip: params.skip,
    data: results,
    data_field: "data",
    message: "Groups fetched successfully.",
  });
};
export const fetchGroupsFromDatabase = async (params) => {
  return postAPICall("GET", `/entity-groups`, params);
};
